.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* [data-testid="change-placeholder-color"]::-webkit-input-placeholder { */

.place-holder-white::-webkit-input-placeholder {
  color: #A7A7A7;
  opacity: 1;
}


/* input:focus, textarea:focus, select:focus{
    outline: none;
} */


 /* ::-webkit-input-placeholder { 
    color: red;
    background: yellow;
 }
 :-moz-placeholder { 
   color: red;
   background: yellow;
 }
 ::-moz-placeholder { 
    color: red;
    background: yellow;
 }
 :-ms-input-placeholder { 
    color: red;
    background: yellow;
 } */





/* /////////////////////// Hover /////////////////////// */


.hover-primary:hover {
  color: #7F3F98;
  cursor: pointer;
}

.hover-danger:hover {
  color: #FF0000;
  cursor: pointer;
}



.hover-bg-primary:hover {
  background-color: #7F3F98;
  cursor: pointer;
}

.hover-bg-danger:hover {
  background-color: #FF0000;
  cursor: pointer;
}

/* /////////////////////// CheckBox /////////////////////// */

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

 /* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #7F3F98;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}